import jQuery from 'jquery/dist/jquery';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import TicketServiceMapEntity from "../../../ticket_service_map/entity";


export default class TicketShowDocuments {
    private parent: any;
    private tableElem: any;
    private datatable: any;
    private entity = "services";
    private toastr: any;
    private datatableElem: any;


    constructor(parent: any) {
        this.parent = parent
        this.toastr = parent.toastr
        this.datatableElem = jQuery(".datatables-ticket-order-document")
        this.bindListeners();
    }

    getEntityData(elem: any) {
        return {...TicketServiceMapEntity.getEntityData(elem), ticket_id: this.parent.id}
    }

    bindListeners() {
    }

    createDataTable() {
        this.datatable = this.datatableElem.DataTable({
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-6 mt-3"p>' +
                '>',
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
            ]
        });
    }

    async update(data: any) {
        if (this.datatable) {
            this.datatable.destroy();
        }
        const table = document.querySelector(".datatables-ticket-order-document tbody") as HTMLElement;
        table.innerHTML = "";
        if (data.orders) {
            data.orders.forEach((order: any) => {
                order.documents.forEach((document: any) => {
                    const tr = `<tr>
                <td>${document.created_at}</td>
                <td>${document.number}</td>
                <td>${Utils.translate(`erp.document.types.${document.document_type}`)}</td>
                <td><div class="d-flex align-items-center"> 
                <a href="${document.url}" target="_blank" class="text-body download-record"> <i class="ti ti-download ti-sm me-2"></i> </a> 
                </div></td>
                </tr>`
                    table.innerHTML = table.innerHTML + tr;
            })
            });
            this.createDataTable();
        }
    }
}